import Moment from "react-moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "antd/dist/antd.css";

import { Steps } from "antd";
import axios from "axios";

import {
  CheckCircleTwoTone,
  CloseCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import history from "../history";

const { Step } = Steps;

const Root = styled.div`
  position: flex;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  position: reletive;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 80%;
  height: auto;
  margin: auto;
  margin-bottom: 20px;
  padding: 0 20px 0 20px;
  margin-top: 20px;

  @media only screen and (max-width: 1020px) {
    width: 100%;
    height: auto;
  }
`;

const Bar = styled.div`
  position: absotute;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 600px) {
    padding: 5px;
    img {
      width: 70px;
    }
    h1 {
      font-size: 15px;
    }
  }
`;

const Seller = styled.div`
  margin-top: 10px;
  margin-left: 140px;
  margin-bottom: 20px;
  @media (max-width: 1040px) {
    margin-left: 40px;
  }
`;

const Stepper = styled.div`
  margin-top: 15px;
  margin-right: 140px;
  margin-left: 40px;

  @media only screen and (min-width: 800px) {
    margin-right: 60px;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 1310px) {
    margin-right: 40px;
    margin-bottom: 20px;
  }
`;
const HR = styled.hr`
  height: 1px;
  background-color: #ccc;
  border: none;
`;

const Tracking = ({location}) => {
  const [advice, setAdvice] = useState("");

  async function fetchData() {
      
    const orderId = new URLSearchParams(location.search).get("orderId");
    const cmn = new URLSearchParams(location.search).get("cmn");
    const url = `https://api-delivery.redorch.com/api/v1/order/check/detail?orderId=${orderId}&cmn=${cmn}`;

    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        setAdvice(response.data);
      }
    } catch (err) {
      history.replace("/notfound");
    }
  }

  useEffect(() => {
    
    
    fetchData();
  }, []);

  if (advice) {
    const handleDate = (created_at) => {
      return (
        <Moment format="  h:mm a, ddd, MMMM Do YYYY " utc="gmt">
          {created_at}
        </Moment>
      );
    };
    return (
      <>
        <Root>
          <Card>
            <Bar>
              <div className="logo">
                <a
                  href="https:bahonexpress.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/s.png" alt="bahonLogo" width="70" height="45" />
                </a>
              </div>

              <div className="tracker">
                <h1 style={{ color: "#635f5e" }}>Parcel Tracking</h1>
              </div>
            </Bar>

            <HR></HR>

            <Bar>
              <div style={{ marginLeft: "40px", fontSize: "18px" }}>
                <h1>Order ID</h1>
              </div>
              <div
                style={{
                  marginRight: "40px",
                  marginLeft: "40px",
                  fontSize: "10px",
                }}
              >
                <h1 style={{ color: "#635f5e" }}>{advice.order_id}</h1>
              </div>
            </Bar>

            <HR></HR>

            <Bar className="Info">
              <Seller>
                <h2>
                  <strong>Seller Info</strong>
                </h2>
                <p style={{ color: "#635f5e" }}>{advice.shop.name}</p>
                <h2>
                  <strong>Receiver Info</strong>
                </h2>
                <p style={{ color: "#635f5e" }}>{advice.recipient_name}</p>
                <p style={{ color: "#635f5e" }}>{advice.recipient_address}</p>
                <p style={{ color: "#635f5e" }}>
                  {advice.recipient_mobile_number}
                </p>
                <h2>
                  <strong>Product Information</strong>
                </h2>
                <p style={{ color: "#635f5e" }}>
                  Recepit &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                  {advice.charges.cod === 0 ? (
                    <strong className="strong">CASH ON DELIVERY</strong>
                  ) : (
                    <strong>Payment Completed</strong>
                  )}
                </p>
                <p style={{ color: "#635f5e" }}>
                  Description &nbsp;&nbsp; &nbsp;&nbsp; Around {advice.weight}gm
                </p>
                <p style={{ color: "#635f5e" }}>
                  price &nbsp;&nbsp; &nbsp;&nbsp; TK{" "}
                  {advice.charges.amount_to_collect}
                </p>
              </Seller>

              <Stepper>
                <Steps
                  direction="vertical"
                  size="small"
                  current={advice?.logs?.length}
                >
                  
                  {advice.logs
                    .slice(0)
                    .reverse()
                    .map((log, id) => {
                      return log.order_state === "Pending" ||
                        log.order_state === "Confirmed" ||
                        log.order_state === "Picked" ||
                        log.order_state === "In-Transit" ||
                        log.order_state === "Out-For-Delivery" ||
                        log.order_state === "Delivered" ? (
                        <Step
                          key={id + "1"}
                          title={log.message}
                          description={handleDate(log.created_at)}
                          icon={<CheckCircleTwoTone twoToneColor="#3AB569" />}
                        />
                      ) : log.order_state === "Cancel" ? (
                        <Step
                          key={id + ""}
                          title={log.message}
                          icon={
                            <CloseCircleOutlined style={{ color: "red" }} />
                          }
                        />
                      ) : (
                        <Step
                          key={id + "3"}
                          title={log.message}
                          icon={<WarningOutlined style={{ color: "#b3b300" }} />}
                        />
                      );
                    })}
                </Steps>
              </Stepper>
            </Bar>
          </Card>
        </Root>
      </>
    );
  }
  return null;
};

export default Tracking;
