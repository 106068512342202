import React from 'react'
import { Redirect, Route, Router, Switch } from "react-router-dom";
import ErrorPage from './component/error';

import history from './history';
import Switcher from "./pages/switcher";
function App() {
  return (
    <div className="App">
     
     <Router history={history}>
        <Switch >
        <Route path="/notfound" component={ErrorPage}/>
          <Route path='/' component={Switcher} />
          <Redirect to="/notfound" />
        </Switch>
    </Router>
    </div>
  );
}

export default App;
