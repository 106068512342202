import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import ErrorPage from "../component/error";
import Tracking from "../component/tracking";

const Container = styled.div`
  padding: 20px;
  margin: 10px;
  height: 100%;
  background-color: #f7f7f7;
`;
const Switcher = () => {
  return (
    <Container>
      <Switch>
        <Route path="/notfound" component={ErrorPage} />
        <Route path="/" component={Tracking} />
        <Redirect to="/notfound" />
      </Switch>
    </Container>
  );
};

export default Switcher;
